<template>

<!--    获取所有vote数据-->
<!--点击跳转至相应vote页面-->

    <div style="width: 95%;float: left">
        <div class="Header_part">
            <div style="width: 50%; text-align:right;float: left;margin-left: 5%">
                <Input search placeholder="">
                    <Select v-model="searchType" slot="prepend" style="width: 130px">
                        <Option value="all">All</Option>
                        <Option value="processed">Processed</Option>
                        <Option value="unprocessed">Unprocessed</Option>
                    </Select>
                </Input>
            </div>

            <div style="width: 30%; text-align:right;float: right; margin-right: 5%">
                <Dropdown style="margin-left: 20px">
                    <Button type="primary">
                        Request Filter
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list" style="text-align: left">
                        <DropdownItem>Unprocessed</DropdownItem>
                        <DropdownItem>Latest</DropdownItem>
                        <DropdownItem>Approved</DropdownItem>
                        <DropdownItem>Rejected</DropdownItem>
                        <DropdownItem>All</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
        <div style="width: 90%; float: left; margin-left: 5%; margin-top: 10px; padding-bottom: 50px">
            <Row style="padding: 1rem; border-radius: 5px 5px 0 0; background-color: #F5F5F5   ; border: #c8e1ff solid 0.0625rem;">
                <Col span="12" style="text-align: left;">
                    <span style="font-size: 1rem; font-weight: 400; margin-left: 0.5rem;">{{ message }}</span>
                </Col>
            </Row>
            <div class="codeTable">
                    <List :data="codeTableData">
                        <ListItem v-for="item in codeTableData" :key="item.userName" style="text-align: left; padding-left: 5%">
                            <Avatar icon="ios-person" size="40"/>
                            <ListItemMeta :avatar="item.avatar" :title="item.userName" :description="item.description" style="text-align: left; padding-left: 5%"/>
                            <template slot="action">
                                <!--<li>
                                    <Button v-if="item.applicationType === 1" style="color:#e9ab01">
                                        This application is being audited . . .
                                    </Button>
                                    <Button v-else-if="item.applicationType === 2"  style="color:#13AF5F">
                                        This application has been approved.
                                    </Button>
                                    <Button v-else style="color:#D13819">
                                        This application has been rejected.
                                    </Button>
                                </li>-->
                                <li>
                                    <Icon @click="check_detail(item.id)" type="ios-arrow-forward" />
                                </li>
                            </template>
                        </ListItem>
                    </List>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "checkJoinList",
        data(){
            return{
                searchType: 'all',
                message:'',
                data1: [
                    {
                        branchName: 'main',
                        IsdDefault: '(default)'
                    },
                    {
                        branchName: 'frontend',
                        IsdDefault: ''
                    },
                    {
                        branchName: 'backend',
                        IsdDefault: ''
                    },
                ],
                codeTableData:[]
            }
        },
        methods: {
            check_detail(id) {
                this.$router.push('/projectEditVote?id='+id)
            },
            getProjectEditVote(){
                this.$axios({
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'put',
                        url: "/projectEdit/getProjectEditByProjectId",
                        data: {
                            projectId:this.projectId
                        }
                    }
                ).then((response) => {
                    let data = response.data.data.items
                    this.codeTableData = data;
                }).catch((error) => {
                    console.log(error);
                })
            },
            init(){
                var Tself = this;
                var val = this.$route.query.id;
                this.projectId = val;
                this.getProjectEditVote();
            }
        },
        mounted() {
            this.init()
        },
    }
</script>

<style scoped>
    .Header_part{
        width:100%;
        float:left;
        margin-top: 20px;
        margin-bottom: 15px;
    }
</style>
